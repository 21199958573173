<div class="footerMainContainer_footer">
    <div class="footer_container">
        <div class="footer_container__mainDiv">
            <div class="footer_container__mainDiv__logo">
                <div class="logoDiv">
                    <div  class="logo logo-left">
                        <img src="{{footerConfig.logoURL}}" alt="{{footerConfig.logoAltText}}" />
                    </div>
                    <div class="logo">
                        <div class="footer-img">
                            <img src="{{getInTouchSection.rightSectionList[0].img}}"
                                alt="{{getInTouchSection.rightSectionList[0].alt}}" class="rightListImg0" />
                                    <span><a class="text" href="{{getInTouchSection.rightSectionList[0].link}}">{{getInTouchSection.rightSectionList[0].text1}}</a></span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img src="{{getInTouchSection.rightSectionList[1].img}}"
                                    alt="{{getInTouchSection.rightSectionList[1].alt}}" class="rightListImg1" />
                                <span><a class="text" href="{{getInTouchSection.rightSectionList[1].link}}">{{getInTouchSection.rightSectionList[1].text1}}</a></span>
                                </div>
                    </div>
                    <div *ngIf="footerConfig.languagePopUp.isShow">
                        <div class="logo logo-right" [ngClass]="{'noPointerEvent': isCountryBtnDisabled}">
                            <span class="country-selector" (click)="onCountrySelection()" appSharedNew tabindex="0" role="button">
                                <span class="spanLabel">{{languageConfig.languagePopUp.label}}</span>
                                <span class="spanImage"><img class="verticalImage" src="{{footerConfig.languagePopUp.selectorIcon}}" alt="{{footerConfig.languagePopUp.altSelectorIcon}}" /></span>
                            </span>
                        </div>
                    </div>

                </div>
            </div>
            <div class="footer_container__mainDiv__link" style="border-bottom: 1px solid #ffffff;">
                <span class="copy-right">&copy;2024 Chubb</span>
                <span *ngFor="let link of footerConfig.footerLinkList">
                    <a (mousedown)="$event.preventDefault()" target="_blank" href="{{link.path}}">{{link.name}}</a>
                </span>
            </div>
            <div class="footer_container__mainDiv__content">{{contentSection.mainDiv}}
                <div class="footer_container__mainDiv__subcontent" style="margin: 0">{{contentSection.footerSubText}}</div>
            </div>
        </div>
    </div>
</div>